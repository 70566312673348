// Logo on Header
 
// DESKTOP
nav {
 	
	.logo-switcher i {
		background-image: url(../img/allskycams_sm.svg);
	    background-size: cover;
	    width: 190px;
	    height: 48px;
	    position: absolute;
	    top: 0;
	    padding-left: 0;
	    margin-left: 0;
	    left: 0;
	}


	.logo {
		opacity:0;
		color: $logo_color;
		padding: $logo-top-padding $logo-right-padding $logo-bottom-padding $logo-left-padding;
 
		img {
			width:100%;
			min-width:$logo-min-width;
		    height: auto;
	    	height: 100%; 
		}

		
	}	 

	&.shrinked {
		.logo {
			opacity:1;
		}
		.home {
			opacity:0;
		}
	}
}


nav .logo {
	// MOBILE 

	@media (max-width:767px) {
			text-align: center;			
			display: block;
    		margin: 0 .5em;

			img {
				max-width: $logo-max-width-mobile;  
			}

	}
}

// Logo in header mobile
span.logo.display-m-b {
	max-width: 90%;
	max-width: 90vh;
    padding: 1.2rem .5rem .45rem;
}

 
 
 