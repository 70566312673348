@import 'slider-pro.core';
@import 'slider-pro.buttons';
@import 'slider-pro.caption';
@import 'slider-pro.full-screen';
@import 'slider-pro.layers';
@import 'slider-pro.thumbnails';
@import 'slider-pro.thumbnail-arrows';
@import 'slider-pro.touch-swipe';


.sp-thumbnail {
    width: 100%;
    padding: .5em;
    color:$main-typo-color;
    background-color: rgb(223, 223, 223);
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    @include transition(all 0.3s cubic-bezier(.25,.8,.25,1));
 
    &:hover {
        color:#fff;
        background-color: rgb(150, 150, 150);
 
    }
}

.sp-bottom-thumbnails.sp-has-pointer .sp-selected-thumbnail:before, .sp-bottom-thumbnails.sp-has-pointer .sp-selected-thumbnail:after {
	border-bottom-color:rgb(223, 223, 223);
}

.sp-bottom-thumbnails.sp-has-pointer .sp-thumbnail {
    position: absolute;
    top: 18px;
    margin-top: 0!important;
    padding-top: .3em;
}



