 #configs {
    .row-flex {
        margin-top:2rem;
    
        .box {
           
            @media(max-width:1130px) {
                max-width: 50rem;
                margin: 0 auto 2rem !important;
            }

        }
    } 

    @media (max-width: 767px) {
        padding:.5em 0;
    }

    
    @media(min-width:767px) {
        
        .rc1 {
            .box:nth-child(1) {
                -webkit-box-ordinal-group: 1;
                -moz-box-ordinal-group: 1;
                -webkit-order: 0;
                -ms-flex-order: 0;
                order: 0;
                -webkit-box-flex: 0;
                -moz-box-flex: 0;
                -webkit-flex: 0 1 auto;
                -ms-flex: 0 1 auto;
                flex: 0 1 auto;
                -webkit-align-self: auto;
                -ms-flex-item-align: auto;
                align-self: auto;
            }

            .box:nth-child(2) {
                -webkit-box-ordinal-group: 1;
                -moz-box-ordinal-group: 1;
                -webkit-order: 0;
                -ms-flex-order: 0;
                order: 0;
                -webkit-box-flex: 0;
                -moz-box-flex: 0;
                -webkit-flex: 0 1.5 auto;
                -ms-flex: 0 1.5 auto;
                flex: 0 1.5 auto;
                -webkit-align-self: auto;
                -ms-flex-item-align: auto;
                align-self: auto;
                margin-left:3em;
            }
        }

        .rc2 {
             .box:nth-child(1) {
                 -webkit-order: 0;
                -ms-flex-order: 0;
                order: 0;
                -webkit-flex:0 0 50%;
                -ms-flex: 0 0 50%;
                flex: 0 0 50%;
                -webkit-align-self: auto;
                -ms-flex-item-align: auto;
                align-self: auto;
                margin-right:1.5em;
            }

            .box:nth-child(2) {
                -webkit-order: 0;
                -ms-flex-order: 0;
                order: 0;
                -webkit-flex: 0 0 50%;
                -ms-flex: 0 0 50%;
                flex: 0 0 50%;
                -webkit-align-self: auto;
                -ms-flex-item-align: auto;
                align-self: auto;
                margin-left:1.5em;
            }
        }
    


    }
     
   

    .box {
        position: relative;
        overflow: hidden;
        p {
            padding-bottom: 1rem;
            margin: 0;
        }
        .ins {
            padding: 1.6rem 1.5rem;
            position: relative;
        }
 
    }

    
}


 