.btn {
    border: none;
    @include border-radius(5px);
    padding: .8em 1em;
    cursor: pointer;
    color: #fff;
    outline: none; 
    @include transition(all 235ms cubic-bezier(.4,0,.2,1)); 
  
    &.btn-big {
      max-width: 25em;
      background-color: $header_bg; 
      font-size: 1rem;
      padding: .8em 3em;
      text-transform: uppercase;
      color: #fff;
      border:none medium;
      
  
      @media(max-width:1350px) {
        max-width:12em; 
        padding:.5em 1em;
      } 

      &:hover {
        color:#fff;
        background-color: rgb(10,63,103);
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
      }
    }

     
}