input[type=text], input[type=email], select, textarea {
    width: 100%;
    padding: .75rem;
    border: 1px solid #ccc;
    border-radius: 3px;
    resize: vertical;
    font-size:1em;
}


// Columbs

fieldset {
    margin: 1rem auto;
    border: none;
    
    display: block;
    max-width: 65rem;
    border-radius: 3px;

    label {
        padding: .5rem .6rem .35rem 0;
        display: inline-block;
        font-weight:bold;
    }
}

.col-25 {
    float: left; 
    width: 25%;
    margin-top: .5rem;
}

.col-75 {
    float: left;
    width: 75%;
    margin-top: .5rem;
}

@media screen and (max-width: 600px) {
    .col-25, .col-75, input[type=submit] {
        width: 100%;
        margin-top: 0;
    }
}
 