// Hamburger
// ==================================================
.hamburger {
  padding: $hamburger-padding-y $hamburger-padding-x;
  display: inline-block;
  cursor: pointer;
  @include transition-property(opacity, filter);
  @include transition-duration($hamburger-hover-transition-duration);
  @include transition-timing-function($hamburger-hover-transition-timing-function);
  
  // Normalize (<button>)
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;

  border-radius:2px;
  padding:.3em .4em .1em;
  background-color:$main-trans-color;

  &:hover {
    @if $hamburger-hover-use-filter == true {
      filter: $hamburger-hover-filter;
    }
    @else {
      opacity: $hamburger-hover-opacity;
    }

     background-color:$main-trans-color-hover;
  }
}

.hamburger-box {
  width: $hamburger-layer-width;
  height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: $hamburger-layer-height / -2;

  &,
  &::before,
  &::after {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height;
    background-color: $hamburger-layer-color;
    border-radius: $hamburger-layer-border-radius;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  &::before,
  &::after {
    content: "";
    display: block;
  }

  &::before {
    top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }

  &::after {
    bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }
}


  .hamburger--elastic {
    .hamburger-inner {
      top: $hamburger-layer-height / 2;

      @include transition-duration(0.275s);
      @include transition-timing-function(cubic-bezier(0.68, -0.55, 0.265, 1.55));

 
      &::before {
        top: $hamburger-layer-height + $hamburger-layer-spacing;
        @include transition(opacity 0.125s 0.275s ease);
      }

      &::after {
        top: ($hamburger-layer-height * 2) + ($hamburger-layer-spacing * 2);
        @include transition(transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55));
      }
    }

    
  }