/* Thumbnail Arrows
--------------------------------------------------*/
.sp-thumbnail-arrows {
	position: absolute;
}

.sp-fade-thumbnail-arrows {
	opacity: 0;
	-webkit-transition: opacity 0.5s;
	transition: opacity 0.5s;
}

.sp-thumbnails-container:hover .sp-fade-thumbnail-arrows {
	opacity: 1;
}

.sp-top-thumbnails .sp-thumbnail-arrows,
.sp-bottom-thumbnails .sp-thumbnail-arrows {
	width: 100%;
	top: 50%;
	left: 0;
	margin-top: -12px;
}

.sp-left-thumbnails .sp-thumbnail-arrows,
.sp-right-thumbnails .sp-thumbnail-arrows {
	height: 100%;
	top: 0;
	left: 50%;
	margin-left: -7px;
}

.sp-thumbnail-arrow {
	position: absolute;
	display: block;
	width: 15px;
	height: 25px;
	cursor: pointer;
}

.sp-left-thumbnails .sp-thumbnail-arrows .sp-thumbnail-arrow,
.sp-right-thumbnails .sp-thumbnail-arrows .sp-thumbnail-arrow {
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}

.sp-top-thumbnails .sp-previous-thumbnail-arrow,
.sp-bottom-thumbnails .sp-previous-thumbnail-arrow {
	left: 0px;
}

.sp-top-thumbnails .sp-next-thumbnail-arrow,
.sp-bottom-thumbnails .sp-next-thumbnail-arrow {
	right: 0px;
}

.sp-left-thumbnails .sp-previous-thumbnail-arrow,
.sp-right-thumbnails .sp-previous-thumbnail-arrow {
	top: 0px;
}

.sp-left-thumbnails .sp-next-thumbnail-arrow,
.sp-right-thumbnails .sp-next-thumbnail-arrow {
	bottom: 0px;
}

.sp-previous-thumbnail-arrow:before,
.sp-previous-thumbnail-arrow:after,
.sp-next-thumbnail-arrow:before,
.sp-next-thumbnail-arrow:after {
	content: '';
	position: absolute;
	width: 50%;
	height: 50%;
	background-color: #FFF;
}

.sp-previous-thumbnail-arrow:before {
	left: 30%;
	top: 0;
	-webkit-transform: skew(145deg, 0deg);
	-ms-transform: skew(145deg, 0deg);
	transform: skew(145deg, 0deg);
}

.sp-previous-thumbnail-arrow:after {
	left: 30%;
	top: 50%;
	-webkit-transform: skew(-145deg, 0deg);
	-ms-transform: skew(-145deg, 0deg);
	transform: skew(-145deg, 0deg);
}

.sp-next-thumbnail-arrow:before {
	right: 30%;
	top: 0;
	-webkit-transform: skew(35deg, 0deg);
	-ms-transform: skew(35deg, 0deg);
	transform: skew(35deg, 0deg);
}

.sp-next-thumbnail-arrow:after {
	right: 30%;
	top: 50%;
	-webkit-transform: skew(-35deg, 0deg);
	-ms-transform: skew(-35deg, 0deg);
	transform: skew(-35deg, 0deg);
}

.ie8 .sp-thumbnail-arrow,
.ie7 .sp-thumbnail-arrow {
	width: 0;
	height: 0;
}

.ie8 .sp-thumbnail-arrow:before,
.ie8 .sp-thumbnail-arrow:after,
.ie7 .sp-thumbnail-arrow:before,
.ie7 .sp-thumbnail-arrow:after {
	content: none;
}

.ie8 .sp-top-thumbnails .sp-previous-thumbnail-arrow,
.ie8 .sp-bottom-thumbnails .sp-previous-thumbnail-arrow,
.ie7 .sp-top-thumbnails .sp-previous-thumbnail-arrow,
.ie7 .sp-bottom-thumbnails .sp-previous-thumbnail-arrow {
	border-right: 12px solid #FFF;
	border-top: 12px solid transparent;
	border-bottom: 12px solid transparent;
}

.ie8 .sp-top-thumbnails .sp-next-thumbnail-arrow,
.ie8 .sp-bottom-thumbnails .sp-next-thumbnail-arrow,
.ie7 .sp-top-thumbnails .sp-next-thumbnail-arrow,
.ie7 .sp-bottom-thumbnails .sp-next-thumbnail-arrow {
	border-left: 12px solid #FFF;
	border-top: 12px solid transparent;
	border-bottom: 12px solid transparent;
}

.ie8 .sp-left-thumbnails .sp-previous-thumbnail-arrow,
.ie8 .sp-right-thumbnails .sp-previous-thumbnail-arrow,
.ie7 .sp-left-thumbnails .sp-previous-thumbnail-arrow,
.ie7 .sp-right-thumbnails .sp-previous-thumbnail-arrow {
	border-bottom: 12px solid #FFF;
	border-left: 12px solid transparent;
	border-right: 12px solid transparent;
}

.ie8 .sp-left-thumbnails .sp-next-thumbnail-arrow,
.ie8 .sp-right-thumbnails .sp-next-thumbnail-arrow,
.ie7 .sp-left-thumbnails .sp-next-thumbnail-arrow,
.ie7 .sp-right-thumbnails .sp-next-thumbnail-arrow {
	border-top: 12px solid #FFF;
	border-left: 12px solid transparent;
	border-right: 12px solid transparent;
}