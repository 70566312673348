// Colors
$header_bg:#01508e; // 0d5286
$mobile-menu-separator-color:rgba(255,255,2555,.3);
$menu_item_color:#fff;     
$logo_color:#fff;                                    
                          
$default-bg:#f0f0f0;      
  
$second-color: #ffb300; //#ffca00; // Yellow
$main-trans-color: rgba(10,63,103,.3); // $header_bg but transparent
$main-trans-color-hover: rgba(10,63,103,.8); // $header_bg but transparent
  

// Fonts 
$main-font: 'helvetica neue',helvetica,arial,sans-serif;
$main-typo-color:#333E46;
 

$max-width: 80em;

 // HEADER & Menu 

 	$header-height-desktop:130px;   // Nav Height
 	$header-height-shrinked:100px; // Nav Height

 	// Main Index Illustration
 	$main-i-height:700px;

	// LOGO (DESKTOP)
	$logo-top-padding:.2em;
	$logo-right-padding:1em;
	$logo-bottom-padding:.2em; 
	$logo-left-padding:.5em;

	$logo-min-width:11.65em;
 	$logo-max-width-mobile:5em;
 
	$header-top-padding:0;
	$header-right-padding:.5em;
	$header-bottom-padding:0;
	$header-left-padding:.5em;

	$menu-item-top-padding:.5em;
	$menu-item-right-padding:.8em;
	$menu-item-bottom-padding:.5em;
	$menu-item-left-padding:.8em;
  
	// DESKTOP MENU
	$nav-max-width: 80em;

	// MOBILE MENU
	$menu-width:18em;
	$mobile-nav-shadow:0 0px 10px rgba(0,0,0,.5);

	// Burger
	$burger_top:.65em;
	$burger_dist_from_border:.5em;
	$hamburger-padding-x : .4em;
	$hamburger-padding-y : .25em ;
	$hamburger-layer-width : 1.5em;
	$hamburger-layer-height : 4px;
	$hamburger-layer-spacing : 6px;
	$hamburger-layer-color : #fff;
	$hamburger-layer-border-radius : 4px;
	$hamburger-hover-opacity : 0.7;
	$hamburger-hover-transition-duration : 0.15s;
	$hamburger-hover-transition-timing-function: linear;
	$hamburger-hover-use-filter: false;
	$hamburger-hover-filter : opacity(80%);
 

// MAIN SECTION 
$main-top-padding:.5em;
$main-right-padding:1em;
$main-bottom-padding:.5em;
$main-left-padding:1em;

// BUTTON
$btn-primary-bg:$header_bg;
