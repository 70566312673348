/* Touch Swipe
--------------------------------------------------*/
.sp-grab {
	cursor: url(../cur/openhand.cur), move;
}

.sp-grabbing {
	cursor: url(../cur/closedhand.cur), move;
}

.sp-selectable {
	cursor: default;
}