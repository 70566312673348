/* Buttons
--------------------------------------------------*/
.sp-buttons {
	position: relative;
	width: 100%;
	text-align: center;
	padding-top: 10px;
}

.sp-rtl .sp-buttons {
	direction: rtl;
}

.sp-full-screen .sp-buttons {
	display: none;
}

.sp-button {
	width: 10px;
	height: 10px;
	border: 2px solid #000;
	border-radius: 50%;
	margin: 4px;
	display: inline-block;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
}

.sp-selected-button {
	background-color: #000;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
	.sp-button {
		width: 14px;
		height: 14px;
	}
}

@media only screen and (min-width: 568px) and (max-width: 768px) {
	.sp-button {
		width: 16px;
		height: 16px;
	}
}

@media only screen and (min-width: 320px) and (max-width: 568px) {
	.sp-button {
		width: 18px;
		height: 18px;
	}
}