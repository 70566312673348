/* Layers
--------------------------------------------------*/
.sp-layer {
	position: absolute;
	margin: 0;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-webkit-font-smoothing: subpixel-antialiased;
	-webkit-backface-visibility: hidden;
}

.sp-black {
	color: #FFF;
	background: rgb(0, 0, 0);
	background: rgba(0, 0, 0, 0.7);
}

.sp-white {
	color: #000;
	background: rgb(255, 255, 255);
	background: rgba(255, 255, 255, 0.7);
}

.sp-rounded {
	border-radius: 10px;
}

.sp-padding {
	padding: 10px;
}