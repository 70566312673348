
 
// Images
img {
	width:100%;
	display: block;
    max-width: 100%;
    height: auto;
    vertical-align: middle;
} 


 
// Images with overlays
.image-wh {

	position:relative;
	overflow:hidden;
	cursor:pointer;

	.msg {
		@include transition(all .5s ease);
	    opacity: 0;
    	position: absolute;
    	top: 100%;
    	left: 50%;
    	@include transform(translate(-50%,0%));
    	text-align:center; 
    	width:100%;  
 
    	span {
    		margin:0 .3rem;
    		padding:1rem;
    		display:block;
    		background-color:rgba(0,0,0,.85);
    		border-radius:5px;
    		font-size: 1rem;
    		color: #fff;
    		text-transform: none;
    		font-style: initial;
    		font-weight: initial;
    	}
 
    }

	&:hover { 
		.msg {
			opacity:1; 
			@include transform(translate(-50%,-107%));
 		}
	}

	
	@media (max-width:767px) {
		// Mode space on mobile
    	margin-left: 1em;
    	margin-right: 1em;
 		
    	&.mob-sh {
    		// Shown by default on mobile
    		.msg {
				opacity:1;
				@include transform(translate(-50%,-107%));
			}
    	} 
    	
 	

    	&.mob-hd  {
    		// Hide message on hover on mobile
    		cursor:default;
    		&:hover {
				.msg {
					opacity:0; 
		 		}
			}
    	}

	}

}



/// .mob-sh : shown by default on mobile
/// .mob-hd : hide on mobile even on hover